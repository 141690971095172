export const NEGOTIATION_CONFIRMATION_ERROR = {
  title: 'Erro ao confirmar sua negociação.',
  message: 'Encontramos um problema durante a confirmação da sua negociação.',
}

export const COPY_BILLET_CODE_MESSAGE = 'Copiar código de barras.'

export const PIX_ERROR_MESSAGE = 'Erro ao buscar informações para o pagamento via PIX'

export const CARD_ERROR_MESSAGE = 'Erro ao buscar informações para o pagamento via cartão'

export const COPIED_BAR_CODE_MESSAGE = 'Código copiado!'
