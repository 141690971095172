export const DRAWER_KEY = {
  NONE: '',
  EMAIL: 'email',
  PLAN: 'plan',
  PHONE: 'phone',
  CALLS: 'calls',
  SERVICES: 'services',
  MODEM: 'modem',
  PASSWORD: 'password',
  COMPLETE: 'complete',
  DUE_DATE: 'dueDate',
  MESSAGES: 'messages',
  VIRTUAL_SUPPORT: 'virtualSupport',
  ADDRESS_CHANGE: 'addressChange',
  ACTIVATION_PENDING: 'activationPending',
  TICKETS: 'tickets',
  NEGOTIATION: 'negotiation',
  FAQ: 'faq',
}
