export const TAGS_INFO = {
  NEGOTIATED: {
    COLOR: 'light',
    TEXT: 'Negociada',
  },
  PAID: {
    COLOR: 'success',
    TEXT: 'Pago',
  },
  OPEN: {
    COLOR: 'warning',
    TEXT: 'Em aberto',
  },
}
