import { DRAWER_KEY } from './drawer'

export const REDIRECT_QUERY = {
  VIRTUAL_SUPPORT: 'redirectSuporteVirtual',
  NEGOTIATION: 'redirectNegotiation',
}

export const DEEP_LINKS_DRAWER = [
  { query: REDIRECT_QUERY.VIRTUAL_SUPPORT, drawerKey: DRAWER_KEY.VIRTUAL_SUPPORT },
  { query: REDIRECT_QUERY.NEGOTIATION, drawerKey: DRAWER_KEY.NEGOTIATION },
]
