import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchContractDetails, fetchTvPlansActivationLink } from 'slices/contract'
import { FIREBASE_EVENTS } from 'app-constants'
import { Card } from 'components'
import { useFirebase } from 'hooks'
import './style.scss'

export function MyServices() {
  const { sendEvent } = useFirebase()
  const {
    detail,
    activationLinks,
    selected: { id: idContract },
  } = useSelector(({ contract }) => contract)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchContractDetails(idContract))
    dispatch(fetchTvPlansActivationLink())
  }, [idContract, dispatch])

  function handleGoToWebsiteClick() {
    sendEvent(FIREBASE_EVENTS.CLICK_ON_SVA_GO_TO_WEBSITE)
  }

  function renderDetailsButton({ urlDocument }) {
    if (!urlDocument) return null

    return (
      <a target='_blank' rel='noopener noreferrer' href={urlDocument} className='link see-details'>
        Ver detalhes
      </a>
    )
  }

  function renderGoToWebsiteButton({ itemCode }) {
    const activationLinkInfo = activationLinks.find(({ tvPlanKey }) => tvPlanKey === itemCode)

    if (!activationLinkInfo?.activationLink) return null

    return (
      <a
        href={activationLinkInfo.activationLink}
        target='_blank'
        rel='noopener noreferrer'
        className='link go-to'
        onClick={handleGoToWebsiteClick}
      >
        Ir para o site
      </a>
    )
  }

  function renderServiceCards() {
    sendEvent(FIREBASE_EVENTS.CLICK_ON_SVA_DETAILS)
    const additionals = detail?.contractAdditionals || []
    const hasAdditionals = additionals.length

    if (hasAdditionals) {
      return additionals.map(additional => {
        const { itemName, urlImage, urlLogo, description } = additional

        return (
          <Card key={itemName} className='card'>
            <div className='container-info'>
              <img src={urlLogo || urlImage} className='img' alt={itemName} />
              <div className='content'>
                <p className='title'>{itemName}</p>
                <p className='info'>{description}</p>
              </div>
            </div>
            <div className='container-actions'>
              {renderDetailsButton(additional)}
              {renderGoToWebsiteButton(additional)}
            </div>
          </Card>
        )
      })
    }
    return <p className='message'>O contrato selecionado não possui serviços contratados.</p>
  }
  if (!detail) return null

  return (
    <div className='my-services-component'>
      <h3 className='title'>Meus serviços</h3>
      <h5 className='subtitle'>
        Seus serviços estão listados abaixo, caso deseja obter mais informações ou configurar algo,
        acesse o site dos serviços contratados.
      </h5>
      <p className='text'>Meus serviços</p>
      {renderServiceCards()}
    </div>
  )
}
