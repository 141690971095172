import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Card, EmptyBills } from 'components'
import { postEvent, useFirebase } from 'hooks'
import { STATICS } from 'statics'
import { fetchInvoices, fetchPaidInvoices } from 'slices/customer'
import { MY_BILLS_PAGE_TAB_LABEL, EVENTS, COMPONENTS_RENDER, FIREBASE_EVENTS } from 'app-constants'
import { FinancialLine } from './components'
import { orderBankSlips } from './utils'
import './style.scss'

const {
  BANKSLIPS: bankSlipTabLabel,
  INVOICES: invoiceTabLabel,
  PAID: paidTabLabel,
} = MY_BILLS_PAGE_TAB_LABEL

// TODO silva.william 30/05/2022: Remover a lógica do botão de atendente para a segunda via quando for estabilizado a migração dos clientes para Giga+
export function MyBills() {
  const { hash } = useLocation()
  const [tab, setTab] = useState(hash.replace('#', ''))
  const [renderClerkAlert, setRenderClerkAlert] = useState(false)
  const { bankSlips, invoices, paid } = useSelector(({ customer }) => customer.financial)
  const {
    selected: { id: idContract },
  } = useSelector(state => state.contract)
  const dispatch = useDispatch()
  const { sendEvent } = useFirebase()

  useEffect(() => {
    dispatch(fetchInvoices(idContract))
  }, [dispatch, idContract])

  useEffect(() => {
    if (paid.page === 0) dispatch(fetchPaidInvoices(idContract))
  }, [dispatch, paid.page, idContract])

  function handleSeeMore() {
    dispatch(fetchInvoices(idContract))
  }

  function handleSeeMorePaid() {
    dispatch(fetchPaidInvoices(idContract))
  }

  function handleTabChange(tabLabel, event) {
    setTab(tabLabel)
    if (event) sendEvent(event)
  }

  function getShouldRenderClerkBillsAlert() {
    return renderClerkAlert && COMPONENTS_RENDER.BANK_SLIPS_CLERK_BUTTON
  }

  function renderBankSlips() {
    postEvent(idContract, EVENTS.PAYMENT_HISTORY_CONSULTATION)
    let component = null

    if (bankSlips.error) {
      component = <EmptyBills billType='Falha ao consultar faturas abertas' />
    } else if (!!bankSlips.content && bankSlips.content.length === 0) {
      component = <EmptyBills billType='Não existem faturas abertas' />
    } else {
      const sortedSlips = orderBankSlips(bankSlips.content)

      component = sortedSlips.map(
        ({ value, urlFile, dueDate, id, contractId, digitableLine, qrCode, tags }) => (
          <FinancialLine
            key={id}
            id={id}
            value={value}
            urlFile={urlFile}
            dueDate={dueDate}
            contractId={contractId}
            codeBar={digitableLine}
            renderClerkAlert={renderClerkAlert}
            setRenderClerkAlert={setRenderClerkAlert}
            qrCode={qrCode}
            tags={tags}
          />
        )
      )
    }

    return component
  }

  function renderInvoices() {
    postEvent(idContract, EVENTS.PAYMENT_HISTORY_CONSULTATION)

    const { content, hasMore, error, isLoading } = invoices
    const hasErrorOrEmpty = error || content?.length === 0

    if (isLoading) {
      return (
        <div>
          <div className='bills-card-loading' />
        </div>
      )
    }

    if (hasErrorOrEmpty)
      return <EmptyBills billType='Não existem notas fiscais para esse contrato.' />

    return (
      <>
        {content.map(({ date, id, urlFile, value }) => (
          <FinancialLine
            key={id}
            value={value}
            urlFile={urlFile}
            dueDate={date}
            btnLabel='Nota fiscal'
            invoice
            paid
          />
        ))}
        {hasMore && (
          <button className='button' onClick={handleSeeMore}>
            Ver mais
          </button>
        )}
      </>
    )
  }

  function renderInvoicesPaid() {
    const { content, hasMore } = paid

    let component = null

    if (paid.error) {
      component = <EmptyBills billType='Falha ao consultar faturas pagas' />
    } else if (!!content && content.length === 0) {
      component = <EmptyBills billType='Não existem faturas pagas' />
    } else {
      component = (
        <>
          {content.map(({ dueDate, paymentDate, id, urlFile, value, contractId }) => (
            <FinancialLine
              key={id}
              value={value}
              urlFile={urlFile}
              dueDate={dueDate}
              paymentDate={paymentDate}
              btnLabel='Baixar fatura'
              paid
              contractId={contractId}
            />
          ))}
          {hasMore && (
            <button className='button' onClick={handleSeeMorePaid}>
              Ver mais
            </button>
          )}
        </>
      )
    }

    return component
  }

  return (
    <div className='my-bills-page'>
      <Link className='return' to='/'>
        <div className='icon'>
          <STATICS.ARROW_BACK />
        </div>
        VOLTAR
      </Link>
      <h1 className='title'>Minhas faturas</h1>
      <Card className='card'>
        <div className='tabs'>
          <button
            className={`tab ${tab === bankSlipTabLabel ? '-active' : ''}`}
            onClick={() => handleTabChange(bankSlipTabLabel)}
          >
            {bankSlipTabLabel}
          </button>
          <button
            className={`tab ${tab === invoiceTabLabel ? '-active' : ''}`}
            onClick={() => handleTabChange(invoiceTabLabel, FIREBASE_EVENTS.CLICK_ON_INVOICES)}
          >
            {invoiceTabLabel}
          </button>
          <button
            className={`tab ${tab === paidTabLabel ? '-active' : ''}`}
            onClick={() => handleTabChange(paidTabLabel, FIREBASE_EVENTS.CLICK_ON_PAYMENT_HISTORY)}
          >
            {paidTabLabel}
          </button>
        </div>
        {tab === bankSlipTabLabel && (
          <>
            {renderBankSlips()}
            {getShouldRenderClerkBillsAlert() && (
              <span className='clerk-warning'>
                Clique em <strong>Falar com Atendente</strong> para solicitar a sua segunda via!
              </span>
            )}
          </>
        )}
        {tab === invoiceTabLabel && renderInvoices()}
        {tab === paidTabLabel && renderInvoicesPaid()}
      </Card>
    </div>
  )
}
