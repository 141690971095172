import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { useFirebase, useForm } from 'hooks'
import { setDrawerAlert, setStage } from 'slices/drawer'
import { REGISTRATION_DATA_STAGES, FIREBASE_EVENTS, EVENT_STATUS } from 'app-constants'
import { Button, Input } from 'components'
import { formatPhoneToDB } from 'utils'
import './style.scss'

const validationSchema = Yup.object().shape({
  phone: Yup.string()
    .required()
    .min(14)
    .max(15),
})

export function PhoneAdd({ data, setData }) {
  const dispatch = useDispatch()
  const {
    info: { contacts },
  } = useSelector(state => state.customer)
  const { sendEvent } = useFirebase()
  const form = useForm({
    initialValues: {
      phone: '',
    },
    validationSchema,
    onSubmit: handleSubmit,
  })

  async function handleSubmit(values) {
    const { phone } = values
    const phoneFormatted = formatPhoneToDB(phone)
    const hasPhone = contacts.find(({ contact }) => contact === phoneFormatted)

    if (hasPhone) {
      dispatch(setDrawerAlert({ severity: 'error', title: 'Telefone já cadastrado' }))
      sendEvent(FIREBASE_EVENTS.CLICK_ON_INFORM_NEW_PHONE, EVENT_STATUS.ERROR)
    } else {
      setData({
        ...data,
        contact: values.phone,
      })

      sendEvent(FIREBASE_EVENTS.CLICK_ON_INFORM_NEW_PHONE)
      dispatch(setStage(REGISTRATION_DATA_STAGES.CONFIRMATION))
    }
  }

  return (
    <form className='phone-add-component' onSubmit={form.handleSubmit}>
      <h3 className='title'>Adicione um novo telefone</h3>
      <p className='subtitle'>
        Deseja incluir mais um telefone a seu cadastro? Insira um telefone abaixo:
      </p>
      <label className='label'>Insira seu novo telefone</label>
      <Input
        className='input'
        name='phone'
        label='Novo telefone'
        onChange={form.handleChange}
        value={form.values.phone}
        mask='(99) 99999-9999'
        hasError={form.errors.phone}
      />
      <Button
        className='button'
        type='submit'
        color='primary'
        size='large'
        isLoading={form.isSubmitting}
      >
        Continuar
      </Button>
    </form>
  )
}
