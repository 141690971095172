import React, { useState } from 'react'
import ReactInputMask from 'react-input-mask'
import { STATICS } from 'statics'
import './style.scss'

export function Input({
  className,
  placeholder,
  type = 'text',
  required,
  value,
  onChange = () => {},
  maxLength,
  name,
  pattern,
  label,
  mask,
  maskChar = '',
  hasError,
  size = 'normal',
  visiblePassword = false,
  disabled,
  errorMessage,
  dataCy,
  checked = false,
}) {
  const [showPassword, setShowPassword] = useState(visiblePassword)

  function resolveClasses() {
    const classes = []

    classes.push('input-component')
    classes.push(`-${size}`)
    classes.push(className || '')

    if (value) classes.push(`-hasValue`)
    if (hasError) classes.push(`-error`)

    return classes.join(' ')
  }

  function resolveType() {
    return type === 'password' && showPassword ? 'text' : type
  }

  function togglePasswordVisibility() {
    setShowPassword(showPassword => !showPassword)
  }

  function renderPasswordIcon() {
    let component = null

    if (type === 'password') {
      component = (
        <button
          type='button'
          className='icon'
          onClick={togglePasswordVisibility}
          data-cy={`button-view-${name}`}
        >
          {showPassword ? <STATICS.EYE_SLASH /> : <STATICS.EYE />}
        </button>
      )
    }

    return component
  }

  return (
    <label data-testid='input-component' className={resolveClasses()}>
      {label && <span className='text'>{label}</span>}
      <ReactInputMask
        className={`input ${!label ? '-hasNoLabel' : ''} -${size}`}
        placeholder={placeholder}
        type={resolveType()}
        required={!!required}
        pattern={pattern}
        name={name}
        value={value}
        onChange={onChange}
        maxLength={maxLength}
        mask={mask}
        maskChar={maskChar}
        disabled={disabled}
        data-cy={dataCy}
        checked={checked}
      />
      {hasError && <p className='error'>{errorMessage}</p>}
      {renderPasswordIcon()}
    </label>
  )
}
