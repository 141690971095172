import { BILLS_TAGS } from 'app-constants'

export function orderBankSlips(brankSlips) {
  return brankSlips.slice().sort((currentSlip, nextSlip) => {
    const { dueDate: curentDueDate } = currentSlip
    const { tags: nextTags, dueDate: nextDueDate } = nextSlip
    const currentDueDateAsDate = new Date(curentDueDate)
    const nextDueDateAsDate = new Date(nextDueDate)
    const isCurrentInNegotiation = nextTags.includes(BILLS_TAGS.IN_NEGOTIATION)

    if (currentDueDateAsDate < nextDueDateAsDate || isCurrentInNegotiation) return -1
    if (currentDueDateAsDate > nextDueDateAsDate) return 1

    return 0
  })
}
