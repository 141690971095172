import {
  RIBEIRAO_PRETO_UNITY,
  RIBEIRAO_PRETO_WHATS_APP,
  WHATSAPP_LINK,
  WHATSAPP_NUMBER,
} from 'app-constants'

function getWhatsAppNumber(unity) {
  if (unity === RIBEIRAO_PRETO_UNITY) {
    return RIBEIRAO_PRETO_WHATS_APP
  }

  return WHATSAPP_NUMBER
}

function buildWhatsAppLink({ number, message }) {
  const whatsappLink = `${WHATSAPP_LINK}${number}`

  if (message) {
    return `${whatsappLink}&text=${window.encodeURIComponent(message)}`
  }

  return whatsappLink
}

export function sendWhatsappMessage({ message, unity, number }) {
  const whatsappNumber = number || getWhatsAppNumber(unity)
  const whatsappLink = buildWhatsAppLink({ number: whatsappNumber, message })

  return window.open(whatsappLink, '_blank')
}
