import { STATICS } from 'statics'
import { useFirebase } from 'hooks'
import { sendWhatsappMessage } from 'utils'
import { DEFAULT_WHATSAPP, FIREBASE_EVENTS } from 'app-constants'
import './style.scss'

export function ContactButton() {
  const { sendEvent } = useFirebase()

  function handleWhatsappClick() {
    sendEvent(FIREBASE_EVENTS.CLICK_ON_CHAT_BUTTON)
    sendWhatsappMessage({ number: DEFAULT_WHATSAPP })
  }

  return (
    <div className='container-contact'>
      <div className='button-container'>
        <button type='button' className='contact-button' onClick={handleWhatsappClick}>
          <div className='icon-container'>
            <STATICS.WHATSAPP_BADGE className='icon' />
          </div>
        </button>
      </div>
    </div>
  )
}
