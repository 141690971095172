/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  BILLS_TAGS,
  CONTRACT_SITUATION,
  CUSTOMER_INVOICES_INITIAL_STATE,
  CUSTOMER_PAID_BILLS_INITIAL_STATE,
  ORIGIN,
} from 'app-constants'
import {
  addContact,
  confirmCellphone,
  confirmEmail,
  deleteContact,
  deleteMessages,
  getBankSlips,
  getContacts,
  getContracts,
  getCustomer,
  getInvoices,
  getMessages,
  getPaidSlips,
  setMainContact,
  updateContact,
  updateMessages,
} from 'hooks'
import { formatPhoneToDB } from 'utils'

const initialState = {
  contracts: null,
  messages: {
    page: 0,
    content: [],
    contentUnread: [],
    hasMore: false,
    isLoading: true,
  },
  info: {
    contacts: [],
    document: '',
    name: '',
    password: '',
    isFirstAccess: true,
    customerId: null,
  },
  financial: {
    bankSlips: {
      isLoading: true,
      content: [],
      hasPendingNegotiated: false,
      daysToBreachOfAgreement: null,
    },
    invoices: CUSTOMER_INVOICES_INITIAL_STATE,
    paid: CUSTOMER_PAID_BILLS_INITIAL_STATE,
    callRecords: [],
  },
}

export const fetchContracts = createAsyncThunk('customer/fetchContracts', async () => {
  const {
    data: { name, contracts },
  } = await getContracts()

  const sortedContracts = contracts.sort((a, b) => {
    if (a.situation === b.situation) {
      return 0
    }
    if (a.situation === CONTRACT_SITUATION.ENABLED) {
      return -1
    }
    if (b.situation === CONTRACT_SITUATION.ENABLED) {
      return 1
    }

    return 0
  })

  return { name, contracts: sortedContracts }
})

export const fetchCustomer = createAsyncThunk('customer/fetchCustomer', async () => {
  const { data } = await getCustomer()

  return data
})

export const fetchContacts = createAsyncThunk('customer/fetchContacts', async () => {
  const { data } = await getContacts()
  return data
})

export const fetchInvoices = createAsyncThunk(
  'customer/fetchInvoices',
  async (contractId, { getState }) => {
    const { invoices } = getState().customer.financial

    const { page } = invoices

    const { data } = await getInvoices(contractId, page + 1, 10)

    return data
  }
)

export const fetchPaidInvoices = createAsyncThunk(
  'customer/fetchPaidInvoices',
  async (contractId, { getState }) => {
    const { paid } = getState().customer.financial

    const { page } = paid

    const { data } = await getPaidSlips(contractId, page + 1, 10)

    return data
  }
)

export const fetchBankSlips = createAsyncThunk('customer/fetchBankSlips', async contractId => {
  const { data } = await getBankSlips(contractId)

  return data
})

export const fetchMessages = createAsyncThunk('customer/fetchMessages', async (_, { getState }) => {
  const { messages, info } = getState().customer

  const { page } = messages
  const { customerId } = info

  const { data } = await getMessages(customerId, ORIGIN, page + 1, 10)

  return data
})

export const readMessage = createAsyncThunk(
  'customer/readMessage',
  async (messageId, { getState }) => {
    const { customerId } = getState().customer.info
    await updateMessages(messageId, customerId)
  }
)

export const removeMessage = createAsyncThunk(
  'customer/removeMessage',
  async (messageId, { getState }) => {
    const { customerId } = getState().customer.info

    await deleteMessages(messageId, customerId)
  }
)

export const updateCustomerContact = createAsyncThunk(
  'customer/updateCustomerContact',
  async customer => {
    const { contactId, contact, contactType, valid, primary } = customer
    await updateContact(contactId, { contact, contactType, valid, primary })
  }
)

export const confirmCustomerPhone = createAsyncThunk(
  'customer/confirmCustomerPhone',
  async ({ contactId, code, phone }, { rejectWithValue }) => {
    try {
      await confirmCellphone(code, formatPhoneToDB(phone))

      return { contactId }
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const confirmCustomerEmail = createAsyncThunk(
  'customer/confirmCustomerEmail',
  async ({ contactId, code, email }, { rejectWithValue }) => {
    try {
      await confirmEmail(code, email)

      return { contactId }
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const addCustomerContact = createAsyncThunk(
  'customer/addCustomerContact',
  async ({ contactType, contact, valid }, { rejectWithValue }) => {
    try {
      const { data } = await addContact({ contactType, contact, valid })

      return { newContactId: data.newContactId, contactType, contact }
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const deleteCustomerContact = createAsyncThunk(
  'customer/deleteCustomerContact',
  async ({ contact, contactType, contactId }) => {
    await deleteContact({ contact, contactType })

    return { contactId }
  }
)

export const setCustomerMainContact = createAsyncThunk(
  'customer/setCustomerMainContact',
  async ({ contactId }) => {
    await setMainContact(contactId)

    return { contactId }
  }
)

export const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    handlePasswordRecovery: (state, action) => {
      state.passwordRecovery = action.payload
    },
    handleContractChange: state => {
      state.financial.invoices = CUSTOMER_INVOICES_INITIAL_STATE
      state.financial.paid = CUSTOMER_PAID_BILLS_INITIAL_STATE
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchContracts.fulfilled, (state, action) => {
        const { name, contracts } = action.payload
        state.contracts = contracts
        state.info.name = name
      })
      .addCase(fetchCustomer.fulfilled, (state, action) => {
        const { customerId } = action.payload
        state.info.customerId = customerId
      })
      .addCase(fetchContacts.fulfilled, (state, action) => {
        state.info.contacts = action.payload
      })
      .addCase(fetchInvoices.fulfilled, (state, action) => {
        state.financial.invoices.isLoading = false
        state.financial.invoices.content.push(...action.payload.content)
        state.financial.invoices.hasMore = action.payload.totalPages > action.payload.page
        state.financial.invoices.page = action.payload.page
      })
      .addCase(fetchInvoices.rejected, state => {
        state.financial.invoices.isLoading = false
        state.financial.invoices.error = true
      })
      .addCase(fetchPaidInvoices.fulfilled, (state, action) => {
        state.financial.paid.isLoading = false
        state.financial.paid.content.push(...action.payload.content)
        state.financial.paid.hasMore = action.payload.totalPages > action.payload.page
        state.financial.paid.page = action.payload.page
      })
      .addCase(fetchPaidInvoices.rejected, state => {
        state.financial.paid.isLoading = false
        state.financial.paid.error = true
      })
      .addCase(fetchBankSlips.fulfilled, (state, action) => {
        const bankSlips = action.payload
        const negotiated = bankSlips.find(bankSlip =>
          bankSlip.tags?.includes(BILLS_TAGS.BREACH_OF_AGREEMENT)
        )
        state.financial.bankSlips.isLoading = false
        state.financial.bankSlips.content = bankSlips
        state.financial.bankSlips.hasPendingNegotiated = !!negotiated
        state.financial.bankSlips.daysToBreachOfAgreement =
          negotiated && negotiated.daysToBreachOfAgreement
      })
      .addCase(fetchBankSlips.rejected, state => {
        state.financial.bankSlips.isLoading = false
        state.financial.bankSlips.content = []
        state.financial.bankSlips.error = true
        state.financial.bankSlips.hasPendingNegotiated = false
        state.financial.bankSlips.daysToBreachOfAgreement = null
      })
      .addCase(fetchMessages.fulfilled, (state, action) => {
        state.messages.isLoading = false
        state.messages.content.push(...action.payload.content)
        state.messages.hasMore = action.payload.totalPages > action.payload.page
        state.messages.page = action.payload.page

        const contentUnread = action.payload.content.filter(msg => !msg.visualized)
        state.messages.contentUnread = contentUnread
      })
      .addCase(fetchMessages.rejected, state => {
        state.messages.isLoading = false
        state.messages.content = []
        state.messages.error = true
      })
      .addCase(readMessage.fulfilled, (state, action) => {
        const messageToBeUpdated = state.messages.content.find(msg => msg.id === action.meta.arg)

        if (messageToBeUpdated) {
          messageToBeUpdated.visualized = true
        }

        state.messages.contentUnread = state.messages.contentUnread.filter(
          msg => msg.id !== action.meta.arg
        )
      })
      .addCase(removeMessage.fulfilled, (state, action) => {
        state.messages.content = state.messages.content.filter(msg => msg.id !== action.meta.arg)
      })
      .addCase(updateCustomerContact.fulfilled, (state, action) => {
        const contactToBeUpdated = state.info.contacts.find(
          contact => contact.contactId === action.meta.arg.contactId
        )

        contactToBeUpdated.confirmed = false
        contactToBeUpdated.contact = action.meta.arg.contact
        contactToBeUpdated.contactType = action.meta.arg.contactType
        contactToBeUpdated.valid = action.meta.arg.valid
      })
      .addCase(deleteCustomerContact.fulfilled, (state, action) => {
        state.info.contacts = state.info.contacts.filter(
          contact => contact.contactId !== action.payload.contactId
        )
      })
      .addCase(setCustomerMainContact.fulfilled, (state, action) => {
        const newMainContact = state.info.contacts.find(
          contact => contact.contact === action.payload.contactId
        )

        const oldMainContact = state.info.contacts.find(
          contact => contact.principal && contact.contactType === newMainContact.contactType
        )

        oldMainContact.principal = false
        newMainContact.principal = true
      })
  },
})

export const { handlePasswordRecovery, handleContractChange } = customerSlice.actions

export default customerSlice.reducer
