export const STAGES = {
  INITIAL: 'initial',
  CONFIRMATION: 'confirmation',
  ERROR: 'error',
  INVOICES_LIST: 'invoices_list',
  OPTIONS: 'options',
  SUCCESS: 'success',
  WITHOUT_OPTIONS: 'without_options',
  REFUSE_OPTIONS: 'refuse_options',
}
