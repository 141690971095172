import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getNegotiationEligible, getNegotiationInvoices } from 'hooks'
import { setStage } from 'slices/drawer'
import { STAGES, DEFAULT_ERROR_STATE } from './constants'
import { ContextLoader } from '../../../context-loader'
import {
  Confirmation,
  Error,
  InvoicesList,
  NegotiationOptions,
  Success,
  WithoutOptions,
  RefuseOptions,
  InitialNegotiation,
} from './components'

function stages({ ...props }) {
  return {
    [STAGES.CONFIRMATION]: <Confirmation {...props} />,
    [STAGES.ERROR]: <Error {...props} />,
    [STAGES.INITIAL]: <InitialNegotiation {...props} />,
    [STAGES.INVOICES_LIST]: <InvoicesList {...props} />,
    [STAGES.OPTIONS]: <NegotiationOptions {...props} />,
    [STAGES.SUCCESS]: <Success {...props} />,
    [STAGES.WITHOUT_OPTIONS]: <WithoutOptions {...props} />,
    [STAGES.REFUSE_OPTIONS]: <RefuseOptions {...props} />,
  }
}

export function NegotiationDrawer() {
  const [isLoading, setIsLoading] = useState(true)
  const [isFirstAccess, setIsFirstAccess] = useState(true)
  const [isEligible, setIsEligible] = useState(false)
  const [negotiation, setNegotiation] = useState(null)
  const [invoices, setInvoices] = useState([])
  const [errorInfo, setErrorInfo] = useState(DEFAULT_ERROR_STATE)
  const [hasError, setHasError] = useState(false)
  const [parcels, setParcels] = useState([])
  const [selectedOption, setSelectedOption] = useState(null)
  const { stage } = useSelector(({ drawer }) => drawer)
  const { id: contractId } = useSelector(({ contract }) => contract.selected)
  const dispatch = useDispatch()

  useEffect(() => {
    async function getInvoices() {
      try {
        const { data } = await getNegotiationInvoices({ contractId })
        const { invoices: userInvoices, parcels: userParcels, ...userNegotiation } = data

        setInvoices(userInvoices)
        setParcels(userParcels)
        setNegotiation(userNegotiation)
      } catch (error) {
        console.error(error)
        setHasError(true)
        setStage(STAGES.ERROR)
      }
    }

    async function getIsEligible() {
      try {
        const { data } = await getNegotiationEligible({ contractId })
        const { isEligible: isUserEligible } = data

        setIsEligible(isUserEligible)
        if (isUserEligible) await getInvoices()
      } catch (error) {
        console.error(error)
        setHasError(true)
        setStage(STAGES.ERROR)
      }

      setIsLoading(false)
    }

    if (isFirstAccess) {
      setIsFirstAccess(false)
      getIsEligible()
    }
  }, [dispatch, isFirstAccess, contractId, isEligible, invoices.length])

  if (isLoading) return <ContextLoader fixed={false} />

  return stages({
    contractId,
    hasError,
    isEligible,
    negotiation,
    invoices,
    parcels,
    errorInfo,
    setErrorInfo,
    selectedOption,
    setSelectedOption,
    setIsLoading,
  })[stage]
}
